$fa-font-path: "../libraries/font-awesome/fonts";

@import "normalize";
@import "neat";
@import 'font-awesome';
@import "starss";
@import "variables";
@import "mixins";

@include normalize();
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600');

@import url('../libraries/lightgallery/dist/css/lightgallery.min.css');
@import url('../libraries/jquery.meanmenu/meanmenu.min.css');


*, *:after, *:before {
  box-sizing: border-box;
}

body {
  font-family: $font;
  line-height: 1.4;
}

b, strong {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: $color-blue;
}

img {
  max-width: 100%;
}


/*********************** FORM *************************/
.form-item, .form-actions {
  margin-bottom: 1em;

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .description {
    margin-top: 5px;
    font-size: 0.85em;
    color: $color-gray;
  }
}

input.form-text, input.form-email, input.form-password, textarea {
  width: 100%;
  padding: 0.5em;
  border: 1px solid $color-gray;
  font-family: $font;

}

input.form-submit, a.button {
  display: inline-block;
  padding: 0.75em 1.25em;
  outline: none;

  cursor: pointer;

  border: 0;
  border-radius: 10px;

  background-color: $color-blue;

  font-family: $font;
  font-size: 0.95em;
  text-transform: uppercase;
  color: white;
}


/*********************** PAGE *************************/

.messages-wrapper {
  display: none;
}

.messages {
  margin: 0;
  border: 0;

  ul {
    margin: 0;
  }

  .messages-inner {
    @include outer-container();
    padding-top: 15px;
    padding-bottom: 15px;
  }

  background-image: none !important;

  &.status {
    background-color: #27ae60;
    color: white;

    a {
      color: white;
    }
  }

  &.error {
    background-color: #e74c3c;
    color: white;

    a {
      color: white;
    }
  }

  &.warning {
    background-color: #f39c12;
    color: white;

    a {
      color: white;
    }
  }

  .close {
    &:after {
      @include font-awesome($fa-var-times);
    }

    float: right;
    font-size: 1.1em;
    cursor: pointer;
  }

  .krumo-root, .krumo-root a {
    color: black !important;
  }
}

.mean-container {
  .mean-bar {
    background-color: $color-blue;

    .mean-nav {
      margin-top: 50px;
      background-color: $color-blue;

      .block__title {
        display: none;
      }
    }
  }
}


body ul.tabs {
  position: fixed;
  z-index: 500;
  left: 5px;
  bottom: 5px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.l-header {
  background-color: $color-blue;
  position: relative;

  .l-header-inner {
    @include outer-container();
  }
}

.l-branding {
  @include grid-column(3);
  text-align: center;
  padding: 15px 0;

  background-color: $color-blue;

  @include grid-media($grid-large) {
    position: absolute;
    z-index: 9999999;
    top: 7px;
    left: 60px;
    padding: 0;
    width: auto;
    margin: 0;
  }
}

.l-region--header {
  @include grid-container();
  @include grid-collapse();

  @include grid-column(9);
  background-color: white;
  height: 76px;

  .block .block__title {
    @include element-invisible();
  }

  .block--system-main-menu {
    @include grid-column(10);

    ul.menu {
      li {
        display: inline-block;

        a {
          position: relative;
          display: inline-block;
          padding: 1.75em 1.2em;

          text-transform: uppercase;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;

            background-color: $color-blue;

            transition: height 250ms;
          }

          &:hover {
            &:after {
              height: 5px;
            }
          }
        }
      }
    }
  }

  .block--on-the-web {
    @include grid-column(1);
    padding: 1.5em 0;

    a {
      display: inline-block;
      padding: 0.25em;
    }

    .otw-facebook {
      a:after {
        @include font-awesome($fa-var-facebook);
      }
    }
  }

  ul.menu, ul.language-switcher-locale-url {
    @include list-clear();
  }

  .block--locale-language {
    @include grid-column(1);
    position: relative;

    ul.language-switcher-locale-url {
      position: absolute;
      top: 0.8em;
      right: 0;
      overflow: hidden;
      max-height: 3.5em;

      transition: all 500ms;

      background-color: white;

      li {
        a {
          display: block;
          padding: 1em;

          text-transform: uppercase;
        }

        &:first-child {
          a:after {
            display: inline-block;
            @include font-awesome($fa-var-angle-down);
            margin-left: 0.75em;
          }
        }
      }

      &.open, &:hover {
        max-height: 8em;
        box-shadow: 0px 0px 4px rgba(black, 0.25);

        li:first-child {
          a:after {
            @include font-awesome($fa-var-angle-up);
          }
        }
      }
    }
  }

  @include grid-media($grid-large) {
    height: 0px;
    .block--locale-language {
      position: static;

      ul.language-switcher-locale-url {
        z-index: 9999999;
        top: 7px;
        right: 20px;
        max-height: 2.5em;

        li a {
          padding: 0.5em 1em;
        }
      }
    }

    .block--on-the-web {
      width: auto;
      position: absolute;
      z-index: 99999999;
      top: 2px;
      right: 125px;
      padding: 0.5em 0;

      a {
        color: white;
      }
    }
  }

  @include grid-media($grid-small) {
    .block--on-the-web {
      display: none;
    }
  }

}

.block--publilux-header {
  .header {
    height: 400px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @include grid-media($grid-small) {
    .header {
      height: 200px;
    }
  }
}

.l-main {
  @include outer-container();
  padding-left: 25px;
  padding-right: 25px;
}

.l-content {
  > h1 {
    @include title-left();
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 1.75em;

    &:after {
      bottom: 10px;
      top: auto;
    }
  }
}


.l-footer {
  margin-top: 2em;
  padding: 1em 0;
  background-color: $color-light-gray;
  font-size: 0.85em;

  &, a {
    color: darken($color-light-gray, 40%);
  }

  .l-region--footer {
    @include outer-container();

    .block {
      @include grid-column(3);

      @include grid-media($grid-medium) {
        height: 75px;
        @include grid-column(3);
      }

      @include grid-media($grid-small) {
        height: auto;
        @include grid-column(4);
      }

      &.footer-address {
        &:before {
          @include font-awesome($fa-var-map-marker);
        }
      }

      &.footer-email {
        &:before {
          @include font-awesome($fa-var-envelope);
        }
      }

      &.footer-phone {
        &:before {
          @include font-awesome($fa-var-phone);
        }
      }

      &.footer-copyright {
        &:before {
          @include font-awesome($fa-var-copyright);
        }
      }

      &.footer-address, &.footer-email, &.footer-phone, &.footer-copyright {
        position: relative;
        padding-left: 2em;

        &:before {
          position: absolute;
          top: 0.6em;
          left: 0;
          font-size: 1.5em;
          color: $color-blue;
        }
      }

    }
  }
}

.view .item-list {
  clear: both;
  padding: 2em;
}

.pager {
  @include list-clear();
  text-align: center;

  > li {
    display: inline-block;

    a, &.pager-current {
      display: inline-block;
      padding: 5px 10px;
      color: $color-gray;
    }

    &.pager-current, a:hover {
      background-color: $color-blue;
      color: white;
      cursor: pointer;

      border-radius: 5px;
    }
  }
}

/*********************** GRID *************************/
@mixin grid($grid, $grid-tablet, $grid-mobile) {
  $base-height: 250px;
  $columns: map-get($grid, 'columns');

  .grid-items {
    @include grid-container($grid);
    @include grid-collapse();
  }

  // WIDTHS
  .grid-1-1, .grid-1-2, .grid-1-3, .grid-1-1-popup, .grid-1-2-popup, .grid-1-3-popup {
    @include grid-column(1, $grid);

    @include grid-media($grid-tablet) {
      @if ($columns % 2 == 0) {
        @include grid-column(($columns / 2));
      } @else {
        @include grid-column($columns);
      }
    }

    @include grid-media($grid-mobile) {
      @include grid-column($columns);
    }
  }

  .grid-2-1, .grid-2-2, .grid-2-3, .grid-2-1-popup, .grid-2-2-popup, .grid-2-3-popup {
    @include grid-column(2, $grid);

    @include grid-media($grid-mobile) {
      @include grid-column($columns);
    }
  }

  .grid-3-1, .grid-3-2, .grid-3-3, .grid-3-1-popup, .grid-3-2-popup, .grid-3-3-popup {
    @include grid-column(3, $grid);

    @include grid-media($grid-tablet) {
      @include grid-column($columns);
    }
  }

  .grid-4-1, .grid-4-2, .grid-4-3, .grid-4-1-popup, .grid-4-2-popup, .grid-4-3-popup {
    @include grid-column(4, $grid);
  }


  // HEIGHTS
  .grid-1-1, .grid-2-1, .grid-3-1, .grid-4-1, .grid-1-1-popup, .grid-2-1-popup, .grid-3-1-popup, .grid-4-1-popup {
    height: $base-height;
    margin-bottom: $grid-gutter;
  }

  .grid-1-2, .grid-2-2, .grid-3-2, .grid-4-2, .grid-1-2-popup, .grid-2-2-popup, .grid-3-2-popup, .grid-4-2-popup {
    height: ($base-height * 2) + $grid-gutter;
    margin-bottom: $grid-gutter;
  }

  .grid-1-3, .grid-2-3, .grid-3-3, .grid-4-3, .grid-1-3-popup, .grid-2-3-popup, .grid-3-3-popup, .grid-4-3-popup {
    height: ($base-height * 3) + ($grid-gutter * 2);
    margin-bottom: $grid-gutter;
  }

}

@mixin grid-styles() {

  &.blue-bar {

    .group-grid-item-link {
      display: block;
      position: relative;

      img {
        display: block;
      }

      .field-name-field-grid-item-label, .field-name-title-field {
        position: absolute;
        top: auto;
        bottom: 0;
        width: 100%;
        padding: 1em;
        text-align: center;
        font-weight: bold;

        background-color: $color-blue;
        color: white;

        text-transform: uppercase;
      }
    }
  }

  &.shaded, &.shaded-inverse {
    .group-grid-item-link, .lightgallery-item {

      &:before {
        content: "";
        position: absolute;
        z-index: 300;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.5);

        transition: 500ms all;
      }

      .field-name-field-grid-item-label {
        position: absolute;
        z-index: 400;
        top: auto;
        bottom: 0;
        width: 100%;
        padding: 1.5em;
        text-align: left;
        font-size: 1.2em;
        font-weight: bold;

        color: white;

        transition: 500ms all;
      }

      &:hover {
        &:before {
          background-color: rgba(black, 0);
        }

        .field-name-field-grid-item-label {
          transform: translateY(-2em);
          opacity: 0;
        }
      }
    }
  }

  &.shaded-inverse {
    .group-grid-item-link, .lightgallery-item {

      &:before {
        background-color: rgba(black, 0);
      }

      .field-name-field-grid-item-label {
        transform: translateY(-2em);
        opacity: 0;
        transition: 500ms all;
      }

      &:hover {
        &:before {
          background-color: rgba(black, 0.5);
        }

        .field-name-field-grid-item-label {
          transform: translateY(0em);
          opacity: 1;
        }
      }
    }
  }

}

/************************* COMPONENTS *******************/

.paragraphs-item-grid {

  h2 {
    @include title();
    margin: 0;
  }

  .paragraphs-item-grid-item {
    overflow: hidden;
  }

  .group-grid-item-link {
    display: block;
    height: 100%;

    position: relative;

    .field-name-field-grid-item-label {
      position: absolute;
      top: 0px;
    }
  }

  .field-name-field-grid-item-image {
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.grid-layout-3-2 {
    @include grid($grid-3, $grid-3-tablet, $grid-3-mobile);
  }

  &.grid-layout-4-3 {
    @include grid($grid-4, $grid-4-tablet, $grid-4-mobile);
  }

  @include grid-styles();


}

.paragraphs-item-textblock, .paragraphs-item-team.view-mode-full {
  h2 {
    @include title-left();
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1em;

    &:after {
      bottom: 10px;
      top: auto;
    }
  }
}

.paragraphs-item-image {
  img {
    display: block;
  }
}

/************************* CLASSES *********************/
.grid-items {
  @include list-clear();
  @include grid($grid-3, $grid-3-tablet, $grid-3-mobile);
  @include grid-styles();
}


/************************* CONTENT *********************/
body.taxonomy-productgroup .l-main, .node-type-product .l-main, .node-type-realisation .l-main {
  max-width: 100%;
}


.node--page {
  &.node--page--featured {

    @media screen and (min-width: 700px) {
      @include grid-container;
    }


    .group-left, .group-right {
      @media screen and (min-width: 700px) {
        @include grid-column(6);


      }
    }

    .group-left {
      margin-top: -75px;

      background-color: $color-blue;

      h2, p {
        color: white;
      }

      .paragraphs-item-textblock {
        padding: 0 1.75em 1.75em 1.75em;
      }
    }
  }
}

.node--webform {
  @include grid-container();

  .group-left {
    @include grid-column(5);
  }

  .group-right {
    @include grid-column(7);
    padding-top: 3em;
    padding-bottom: 3em;
  }

  @include grid-media($grid-medium) {
    .group-left, .group-right {
      @include grid-column(6);
    }
  }
}

.taxonomy-term--productgroup--full, .node--product--full, .node--realisation--full {
  .header {
    background-color: $color-light-gray;
    margin-left: -25px;
    margin-right: -25px;

    .header-inner {
      @include outer-container;
      @include grid-container;

      .group-left {
        @include grid-column(7);
        padding: 1em 2em 2em 2em;

        h2 {
          @include title-left();
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 1em;

          &:after {
            top: 2em;
          }
        }
      }

      .group-right {
        @include grid-column(5);

        img {
          display: block;
          object-fit: cover;
        }
      }
    }
  }

  .group-content {
    @include outer-container();
    padding-top: 35px;
  }

  @include grid-media($grid-medium) {
    .header {
      .header-inner {
        .group-left {
          @include grid-column(6);
        }

        .group-right {
          display: none;
        }
      }
    }
  }
}


.field-name-field-teamleden {
  @media screen and (min-width: 700px) {
    display: flex;
    flex-wrap: wrap;
  }


  > div {
    @media screen and (min-width: 700px) {
      width: 40%;

      &:nth-child(even) {
        margin-left: auto;
      }
    }
    margin-bottom: 30px;


  }
}


.node--blog-item--teaser {
  display: flex;
  flex-wrap: wrap;

  > div.group-left {
    width: 300px;
  }

  > div.group-right {
    width: calc(100% - 320px);
    margin-left: auto;

    h2 {
      margin: 0;
    }

    .field-name-post-date {
      font-size: 14px;
    }

    .field-name-node-link {
      a {
        display: block;

      }
    }
  }
}


body.node-type-blog-item {

}

.node--blog-item--full {
  position: relative;

  .field-name-backtoblog {


    @media screen and (max-width: 700px) {
      margin-bottom: 10px;
    }

    @media screen and (min-width: 700px) {
      top: -85px;
      position: absolute;
      right: 0;
    }
  }

  .field-name-body{
    margin-bottom: 30px;
  }

  .field-name-post-date {
    font-size: 14px;
    margin-bottom: 30px;
  }

}

.socialshare {



    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;


  li {
    &:not(:last-child) {
      margin-right: 13px;
    }
  }

  a {
    display: block;
    text-decoration: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-indent: -9999px;
    background: #004494 no-repeat center center;
    transition: all 0.2s;


    &.social__facebook {
      background-image: url(../images/fb--white.svg);
      background-size: 11px 19px;
    }

    &.icon-linkedin {
      background-image: url(../images/linkedin--white.svg);
      background-size: 19px 18px;
    }

    &.social__twitter {
      background-image: url(../images/twitter--white.svg);
      background-size: 19px 17px;
    }
  }
}