$debug: false;

// COLORS
$color-blue: #004494;
$color-red: #e1001a;
$color-gray: #aaa;
$color-light-gray: #f3f3f3;

// FONTS
$font: 'Source Sans Pro', sans-serif;

// GRID
$grid-gutter: 20px;
$grid-base-width: 1180px;
$grid-visual: $debug;

$neat-grid: (
  columns: 12,
  gutter: $grid-gutter
);

$grid-3: (
  columns: 3,
  gutter: $grid-gutter
);

$grid-4: (
  columns: 4,
  gutter: $grid-gutter
);

$grid-3-tablet: (
  columns: 3,
  gutter: $grid-gutter,
  media: 'screen and (max-width: 768px)'
);

$grid-4-tablet: (
  columns: 4,
  gutter: $grid-gutter,
  media: 'screen and (max-width: 768px)'
);

$grid-3-mobile: (
  columns: 3,
  gutter: $grid-gutter,
  media: 'screen and (max-width: 480px)'
);

$grid-4-mobile: (
  columns: 4,
  gutter: $grid-gutter,
  media: 'screen and (max-width: 480px)'
);

$grid-large: (
  columns: 12,
  media: 'screen and (max-width: 1024px)',
);

$grid-medium: (
  columns: 6,
  media: 'screen and (max-width: 768px)',
);

$grid-small: (
  columns: 4,
  media: 'screen and (max-width: 480px)',
);
