// =================================== CONTAINER
@mixin outer-container()
{
  @include grid-container;
  max-width: $grid-base-width;
  margin: 0 auto;

  @if ($grid-visual) {
    @include grid-visual;
  }
}

// =================================== TYPOGRAPHY
@mixin title() {
  position: relative;
  padding: 2em 1.5em 2em 1.5em;
  font-size: 2.25em;
  text-align: center;
  color: $color-blue;
  font-weight: 700;

  margin-bottom: 0.5em;

  &:after {
    content: "";
    position: absolute;
    top: 4em;
    left: 50%;
    margin-left: -5%;
    width: 10%;
    border-bottom: 3px solid $color-red;
  }
}

@mixin title-left() {
  @include title();

  text-align: left;

  &:after {
    left: 0;
    margin-left: 0;
  }
}

// =================================== LISTS
@mixin list-clear()
{
  &, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
