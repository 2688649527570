// =================================== LAYOUT
@mixin site-width {
  max-width: $l-site-width;
  width: 100%;
  margin: 0 auto;
}

@mixin element-invisible {
  position: absolute;
  top: 0px;
  left: 0;
  height: 0px;
  width: 0px;
  overflow: hidden;
}

// =================================== LISTS
@mixin list-clear() {
  list-style: none;
  padding: 0;
  margin: 0;

  li, .leaf {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

// =================================== TEXT
@mixin font-awesome($icon) {
  @include fa-icon();
  content: $icon;
}

// =================================== BOURBON
@mixin row-table() {
  display: table;
  width: 100%;
}

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

